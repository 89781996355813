import React from 'react';
import PageBanner from '../../containers/PageBanner';
import ProjectDetailsContainerAy from '../../containers/ProjectContainers/ProjectDetailsContainerAy';

const ProjectDetailsAy = () => {
    return (
        <main className="wrapper">
            <PageBanner pageTitle='Projeler' title="Proje detayları" activePage="Tek katlı müstakil yapı" />
            <ProjectDetailsContainerAy />

        </main>
    );
};

export default ProjectDetailsAy;