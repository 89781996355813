import React from "react";
import ContactContainer from "../containers/ContactContainer";
import PageBanner from "../containers/PageBanner";

const Contact = () => {
  return (
    <>
      <PageBanner
        pageTitle="Sayfalar"
        title="İletişim"
        activePage="İletişim"
      ></PageBanner>
      <ContactContainer />
    </>
  );
};

export default Contact;
