import React from "react";

const TextAbout = () => {
  return (
    <section className="highlight_banner bg-dark-200">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-11 p-lg-0">
            <p className="about_para text-center">
              <span>
                <a href="#">Her gün</a>
              </span>{" "}
              yeni hayallerle gelen yeni insanlarla tanışıyoruz ve çabamızın{" "}
              <span>
                <a href="#">onları gerçeğe dönüştürmek olduğunu umuyoruz</a>
              </span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TextAbout;
