import React from 'react';
import PageBanner from '../../containers/PageBanner';
import ProjectDetailsContainerMfi from '../../containers/ProjectContainers/ProjectDetailsContainerMfi';

const ProjectDetailsMfi = () => {
    return (
        <main className="wrapper">
            <PageBanner pageTitle='Projeler' title="Proje detayları" activePage="Tek katlı müstakil yapı" />
            <ProjectDetailsContainerMfi />

        </main>
    );
};

export default ProjectDetailsMfi;