import React from "react";
import PageBanner from "../../containers/PageBanner";
import TeamDetailsContainer from "../../containers/TeamContainers/TeamDetailsContainer";

const TeamDetails = () => {
  return (
    <>
      <PageBanner
        pageTitle="Sayfalar"
        title="Rümeysa ADA KUBAL"
        activePage="Takım"
      />
      <TeamDetailsContainer />
    </>
  );
};

export default TeamDetails;
