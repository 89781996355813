import React from 'react';
import PageBanner from '../../containers/PageBanner';
import ProjectDetailsContainerUa from '../../containers/ProjectContainers/ProjectDetailsContainerUa';

const ProjectDetailsUa = () => {
    return (
        <main className="wrapper">
            <PageBanner pageTitle='Projeler' title="Proje detayları" activePage="Tek katlı müstakil yapı" />
            <ProjectDetailsContainerUa />

        </main>
    );
};

export default ProjectDetailsUa;