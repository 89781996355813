import React from "react";

const MissionContainer = () => {
  return (
    <section className="mission">
      <div className="container">
        <div className="mission_top_part">
          <div className="section-header text-center">
            <h5 className="text-white">GÖREVİMİZ</h5>
            <p className="text-gray-600">
              Organizasyonel ruhumuz, tasarımın tutku ve sevgiyle mekanla
              buluştuğu yerde iyi mimarinin ortaya çıktığına inanır
            </p>
          </div>
          <div className="has_line"></div>
          <img src="images/bg/about_bg.jpg" alt="" />
        </div>

        <div className="mission_bottom_part">
          <div className="row justify-content-center">
            <div className="col-lg-4 pe-lg-4">
              <div className="section-header">
                <h3 className="text-white text-uppercase border-line">
                  MİMARLIK AİT OLDUĞUMUZ YERDİR
                </h3>
              </div>
            </div>
            <div className="col-lg-6 ps-lg-4">
              <div className="mission_content">
                <p>
                  RAK Mimarlık, işlevsel mekanlar üretmeyi amaçlayan, yere ve
                  zamana ait insanı ön planda tutan yapılar tasarlamayı hedef
                  edinen bir mimarlık ofisidir. 2023 yılında kurulan ofis,
                  çeşitli konut, ticari ve karma kullanımlı yapıları estetik
                  kaygılarla tasarlamaktadır. Çevreye duyarlı yaklaşımlar
                  oluştururken teknolojik gelişmelere uyumlu yapılar üretmeyi
                  önemseyen RAK, 3D görsellerle projeleri müşteri memnuniyetine
                  sunmaktadır.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MissionContainer;
