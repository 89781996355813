import React from "react";

const AsideInfo = () => {
  return (
    <div className="aside_info_wrapper">
      <button className="aside_close">
        <i className="bi bi-x-lg"></i>
      </button>
      <div className="aside_logo">
        <a href="/" className="light_logo">
          <img src="images/logo-light-lg.png" alt="logo" />
        </a>
        <a href="/" className="dark_logo">
          <img src="images/logo-dark-lg.png" alt="logo" />
        </a>
      </div>
      <div className="aside_info_inner">
        <p>
          RAK, mimarlık ve tasarım fikirlerini, kültürel devamlılık çerçevesinde
          kurgulayarak insanı önde tutan projeler üreten mimarlık ofisidir.
        </p>

        <div className="aside_info_inner_box">
          <h5>İletişim</h5>
          <p>+90 531 681 39 19</p>
          <p>
            Camikebir Mah. Rıhtım Cad. Pak-Ekizceli İş Merkezi <br /> B Blok
            Kat:3 Ofis No:325 Merkez / DÜZCE
          </p>
          <p> info@rakmimarlik.com</p>

          {/* <h5>Office Address</h5>
          <p>
            +Time Square, New York <br /> USA, 3454
          </p> */}
        </div>
        <div className="social_sites">
          <ul className="d-flex align-items-center justify-content-center">
            <li>
              <a>
                <i className="bi bi-facebook"></i>
              </a>
            </li>
            <li>
              <a>
                <i className="bi bi-twitter"></i>
              </a>
            </li>
            <li>
              <a>
                <i className="bi bi-instagram"></i>
              </a>
            </li>
            <li>
              <a>
                <i className="bi bi-youtube"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AsideInfo;
